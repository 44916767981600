<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000"
    width="85%"
    id="activationDialog"
  >
    <v-card>
      <v-toolbar dark color="dark">
        <v-toolbar-title class="primary--text"
          >LICENCE REQUIRED</v-toolbar-title
        >
      </v-toolbar>
      <v-form class="pa-5" style="text-align:center">
        <p class="headline font-weight-light">
          This Software licence appears to have expired.
        </p>
        <p class="font-weight-thin">
          Please enter your activation code below to continue using this
          product.
        </p>
        <div style="margin: 0 25% 0 25%;">
          <v-layout row id="activationCode">
            <v-text-field
              v-for="(_, i) in digits"
              :key="`digit-${i}`"
              :ref="`digit-${i}`"
              :autofocus="i == 0"
              @keyup="backspace($event, i)"
              v-model="digits[i]"
              class="display-1 font-weight-light"
              color="primary"
              required
              mask="#"
              browser-autocomplete="off"
              @focus="$set(digits, i, null)"
              @input="checkDigits($event, i)"
              :disabled="locked"
            />
          </v-layout>
          <div id="errorContainer">
            <v-progress-linear
              color="primary"
              height="2"
              class="py-0 my-0"
              :active="loading"
              indeterminate
            ></v-progress-linear>
            <v-text-field
              height="0"
              class="ma-0 pa-0"
              :error-messages="attemptsLeft"
              disabled
            />
            <v-alert class="mt-3" :value="error" outline color="red">
              {{ errorMessage }}
            </v-alert>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LicenceDialog',
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    digits: Array(6),
    loading: false,
    error: false,
    attempts: 0
  }),
  computed: {
    code() {
      return this.digits.reduce((acc, val) => acc + val, '')
    },
    errorMessage() {
      return this.locked
        ? 'System locked down, please contact DroneShield for assistance.'
        : 'Incorrect code, please try again.'
    },
    attemptsLeft() {
      return this.attempts >= 5 && !this.locked
        ? `You have <strong>${10 - this.attempts} attempt${
            this.attempts == 9 ? '' : 's'
          }</strong> remaining.`
        : ''
    },
    locked() {
      return this.attempts >= 10
    }
  },
  methods: {
    ...mapActions('system', ['VALIDATE_LICENCE']),
    checkDigits(v, i) {
      const nextDigit = parseInt(i) + 1
      if (v.length == 1) {
        this.error = false
        if (nextDigit == this.digits.length) {
          this.$refs[`digit-${i}`][0].$el.querySelector('input').blur()
          this.loading = true
          this.sendCode()
        } else {
          this.$refs[`digit-${nextDigit}`][0].$el.querySelector('input').focus()
        }
      }
    },
    backspace(e, i) {
      const prevDigit = parseInt(i) - 1
      if (prevDigit >= 0 && e.code === 'Backspace') {
        this.$refs[`digit-${prevDigit}`][0].$el.querySelector('input').focus()
      }
    },
    resetDigits() {
      this.digits = Array(6)
      this.$refs['digit-0'][0].$el.querySelector('input').focus()
      document
        .querySelectorAll('#activationCode input')
        .forEach(d => d.classList.remove('validatedLicence'))
    },
    async sendCode() {
      const res = await this.VALIDATE_LICENCE(this.code)
      this.loading = false
      if (res.valid_code) {
        document
          .querySelectorAll('#activationCode input')
          .forEach(d => d.classList.add('validatedLicence'))
        this.$store.dispatch('auth/CLEAR_ERROR')
        setTimeout(() => this.$emit('onClose'), 1000)
      } else {
        this.error = true
        this.attempts = res.trials || 10
        this.resetDigits()
      }
    }
  },
  watch: {
    dialog(v) {
      const appClass = document.querySelector('.application--wrap').classList
      if (v) {
        this.resetDigits()
        appClass.add('blurred_bg')
      } else {
        appClass.remove('blurred_bg')
      }
    }
  }
}
</script>

<style>
#errorContainer .v-input__slot {
  display: none;
}
#activationCode .v-text-field__details {
  display: none;
}
#activationCode input {
  text-align: center !important;
}
#activationCode .v-input__slot:before {
  border-color: transparent;
}
#activationCode .v-input__slot {
  padding: 30% 0 30% 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  min-width: 50px;
}
#activationCode .v-input:first-child .v-input__slot {
  border-radius: 10px 0px 0px 0px;
}
#activationCode .v-input:last-child .v-input__slot {
  border-radius: 0px 10px 0px 0px;
}
#activationCode .v-input {
  padding-right: 5px;
}
#activationCode .v-input:last-child {
  padding-right: 0px;
}
#activationCode .v-input:nth-child(3) {
  padding-right: 20px;
}

#activationCode .v-input__slot:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
#activationCode .v-input__slot:focus-within {
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.8);
}
#activationCode input {
  transition: color 1s ease, text-shadow 1s ease;
}
.validatedLicence {
  color: #ff9800 !important;
  text-shadow: 0px 0px 10px #ff9800;
}
.blurred_bg {
  filter: blur(3px) !important;
  -webkit-filter: blur(3px) !important;
  -moz-filter: blur(3px) !important;
  -o-filter: blur(3px) !important;
  -ms-filter: blur(3px) !important;
}
</style>
